import React from "react";
import Layout from "../components/layout";
import SEO from "../components/Seo";

import { FormattedMessage, Link } from "gatsby-plugin-intl";

const SuccessPage = () => {
  return (
    <Layout className="transition-fade">
      <SEO title="Contact Success" />

      <div id="success-page">
        <div className="header">
          <div className="success-content-header">
            <h1 className="success-content-title">
              <FormattedMessage id="success_title" />
            </h1>
          </div>
        </div>

        <div className="page-content">
          <p>
            <FormattedMessage id="success_content" />
          </p>
          <Link to={"/"}>
            <button role="button" className="outline d-block">
              <FormattedMessage id="back-to-home" />
            </button>
          </Link>
        </div>
      </div>
    </Layout>
  );
};

export default SuccessPage;